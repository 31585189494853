exports.components = {
  "component---src-sites-pl-404-js": () => import("./../../../src/sites/PL/404.js" /* webpackChunkName: "component---src-sites-pl-404-js" */),
  "component---src-sites-pl-kampanie-odkryj-swiat-po-jsx": () => import("./../../../src/sites/PL/kampanie/odkryj-swiat-po.jsx" /* webpackChunkName: "component---src-sites-pl-kampanie-odkryj-swiat-po-jsx" */),
  "component---src-sites-pl-kampanie-rejestracja-jsx": () => import("./../../../src/sites/PL/kampanie/rejestracja.jsx" /* webpackChunkName: "component---src-sites-pl-kampanie-rejestracja-jsx" */),
  "component---src-sites-pl-kampanie-testowanie-karty-jsx": () => import("./../../../src/sites/PL/kampanie/testowanie-karty.jsx" /* webpackChunkName: "component---src-sites-pl-kampanie-testowanie-karty-jsx" */),
  "component---src-sites-pl-p-aplikacja-mobilna-jsx": () => import("./../../../src/sites/PL/p/aplikacja-mobilna.jsx" /* webpackChunkName: "component---src-sites-pl-p-aplikacja-mobilna-jsx" */),
  "component---src-sites-pl-p-archiwum-obowiazkow-informacyjnych-jsx": () => import("./../../../src/sites/PL/p/archiwum-obowiazkow-informacyjnych.jsx" /* webpackChunkName: "component---src-sites-pl-p-archiwum-obowiazkow-informacyjnych-jsx" */),
  "component---src-sites-pl-p-archiwum-regulaminow-jsx": () => import("./../../../src/sites/PL/p/archiwum-regulaminow.jsx" /* webpackChunkName: "component---src-sites-pl-p-archiwum-regulaminow-jsx" */),
  "component---src-sites-pl-p-kariera-jsx": () => import("./../../../src/sites/PL/p/kariera.jsx" /* webpackChunkName: "component---src-sites-pl-p-kariera-jsx" */),
  "component---src-sites-pl-p-kategorie-prismic-category-uid-jsx": () => import("./../../../src/sites/PL/p/kategorie/{PrismicCategory.uid}.jsx" /* webpackChunkName: "component---src-sites-pl-p-kategorie-prismic-category-uid-jsx" */),
  "component---src-sites-pl-p-kategorie-prismic-subcategory-data-category-uid-prismic-subcategory-uid-jsx": () => import("./../../../src/sites/PL/p/kategorie/{PrismicSubcategory.data__category__uid}/{PrismicSubcategory.uid}.jsx" /* webpackChunkName: "component---src-sites-pl-p-kategorie-prismic-subcategory-data-category-uid-prismic-subcategory-uid-jsx" */),
  "component---src-sites-pl-p-kontakt-jsx": () => import("./../../../src/sites/PL/p/kontakt.jsx" /* webpackChunkName: "component---src-sites-pl-p-kontakt-jsx" */),
  "component---src-sites-pl-p-partner-prismic-partner-business-card-uid-jsx": () => import("./../../../src/sites/PL/p/partner/{PrismicPartnerBusinessCard.uid}.jsx" /* webpackChunkName: "component---src-sites-pl-p-partner-prismic-partner-business-card-uid-jsx" */),
  "component---src-sites-pl-p-prismic-campaign-uid-jsx": () => import("./../../../src/sites/PL/p/{PrismicCampaign.uid}.jsx" /* webpackChunkName: "component---src-sites-pl-p-prismic-campaign-uid-jsx" */),
  "component---src-sites-pl-p-regulaminy-jsx": () => import("./../../../src/sites/PL/p/regulaminy.jsx" /* webpackChunkName: "component---src-sites-pl-p-regulaminy-jsx" */),
  "component---src-sites-pl-pay-error-jsx": () => import("./../../../src/sites/PL/pay/error.jsx" /* webpackChunkName: "component---src-sites-pl-pay-error-jsx" */),
  "component---src-sites-pl-pay-success-jsx": () => import("./../../../src/sites/PL/pay/success.jsx" /* webpackChunkName: "component---src-sites-pl-pay-success-jsx" */),
  "component---src-sites-pl-pay-transaction-uid-jsx": () => import("./../../../src/sites/PL/pay/[transactionUid].jsx" /* webpackChunkName: "component---src-sites-pl-pay-transaction-uid-jsx" */),
  "component---src-templates-customer-dashboard-tiles-jsx": () => import("./../../../src/templates/CustomerDashboardTiles.jsx" /* webpackChunkName: "component---src-templates-customer-dashboard-tiles-jsx" */),
  "component---src-templates-dostepnesklepy-jsx": () => import("./../../../src/templates/dostepnesklepy.jsx" /* webpackChunkName: "component---src-templates-dostepnesklepy-jsx" */),
  "component---src-templates-index-home-jsx": () => import("./../../../src/templates/IndexHome.jsx" /* webpackChunkName: "component---src-templates-index-home-jsx" */)
}

